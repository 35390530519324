/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/

//Font families
$sans-serif-font: 'Neue Helvetica W01', Helvetica, 'Trebuchet MS', Arial, sans-serif;
$sans-serif-alt-font: 'Saira W01', Impact, sans-serif;
$serif-font: sans-serif;
$heading-font: $sans-serif-alt-font;
$main-font: $sans-serif-font;
$alt-font: $heading-font;

//Font weights
$main-normal: 400;
$main-bold: 700;
$alt-light: 300;
$alt-normal: 400;
$alt-bold: 600;
$heading-light: $alt-light;
$heading-normal: $alt-normal;
$heading-bold: $alt-bold;

//Font sizes
$font-sizes: (
  small: (rem-calc(13), 1.54),
  default: (rem-calc(16), 1.5),
  large: (rem-calc(20), 1.25),
  small-desktop: (rem-calc(20), 1.5),
  default-desktop: (rem-calc(22), 1.67),
  large-desktop: (rem-calc(29), 1.31)
);

//Styleguide colors
$brand:				 #990011;
$brand-accent:		 #E62117;
$brand-alt:			 #F1A6AF;
$brand-alt-accent:	 #FFA600;
$med-blue:           #3b6fa1;
$white:              #FFFFFF;
$black:              #000000;
$gray:               #262626;
$off-white:          #f6f6f6;
$green:              #44cda3;
$red:                #ff2b59;

$colors: (
	'brand':			 $brand,
	'brand-accent':		 $brand-accent,
	'brand-alt':		 $brand-alt,
	'brand-alt-accent':	 $brand-alt-accent,
	'med-blue':          $med-blue,
	'white':             $white,
	'black':             $black,
	'gray':              $gray,
	'off-white':         $off-white,
	'green':             $green,
	'red':               $red
);

//Gradients, for use on backgrounds only
$gradients: (
	'white': linear-gradient(180deg, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%)
);

//Transparent background colors
$brand-trans:        rgba($brand,0.8);
$black-trans:        rgba($black,0.7);

// Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: #242424;

//Link colors
$link-base: $med-blue;
$link-active: $brand-accent;

//Social media brand colors
$social: (
	'twitter': 		 #00aced,
	'facebook': 	 #3b5998,
	'googleplus': 	 #dd4b39,
	'pinterest': 	 #cb2027,
	'linkedin': 	 #007bb6,
	'youtube': 		 #bb0000,
	'vimeo': 		 #aad450,
	'instagram': 	 #517fa4,
	'quora': 		 #a82400,
	'foursquare': 	 #0072b1,
	'stumbleupon': 	 #EB4823,
	'soundcloud': 	 #ff3a00
);

//Shadows
$text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
$text-shadow-light: 1px 1px 1px rgb(255,255,255);
$box-shadow: 0 1px 1px rgba(0,0,0,1);

//Rounded corners
$border-round: 1000px;
$border-radius: 2px;

//Section spacing
$full-section-spacing-small: 		48px;
$content-section-spacing-small: 	48px;
$content-sub-section-spacing-small: 24px;
$intro-section-spacing-small: 		48px;
$full-section-spacing: 				64px;
$content-section-spacing: 			64px;
$content-sub-section-spacing: 		32px;
$intro-section-spacing: 			64px;

//Hero backgrounds
$hero-bg: (
	'asbestos-wall': asbestos_wall,
	'floor-light': floor_light,
	'studs': studs,
	'clean-room': clean_room,
	'stripped-floor': stripped_floor,
	'demolition': demolition
);

$hero-bg-color: (
	'asbestos-wall': #a1a09e,
	'floor-light': #443c2f,
	'studs': #625b47,
	'clean-room': #776458,
	'stripped-floor': #776458
);