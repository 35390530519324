.team-grid {
	.team-member {
		max-width: 600px;
		margin: 0 auto 40px;
	}
	.name {
		font-size: rem-calc(28);
		font-weight: $heading-bold;
		color: $brand;
		letter-spacing:  .32px;
		margin: 0;
	}
	.title {
		font-family: $heading-font;
		font-weight: $alt-normal;
		font-size: rem-calc(20);
		color: $gray;
	}
	.phone {
		font-family: $alt-font;
		font-size: rem-calc(20);
		color: $brand;
		margin: 0;
	}
	.email {
		margin: 0;
		a {
			font-family: $alt-font;
			font-size: rem-calc(20);
			letter-spacing: .43px;
			text-decoration: underline;
			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}
	p {
		margin: 8px 0 0;
	}
}

@include breakpoint(medium) {
	.team-grid {
		.headline {
			display: flex;
			align-items: center;
		}
		.title {
			margin-left: 10px;
		}
	}
}

@include breakpoint(large) {
	.team-grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 72px;
	}
}