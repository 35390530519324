/*------------------------------------*\
    @group Hero
\*------------------------------------*/
.hero {
	background-image: url('/images/hero/bg_hero.jpg');
	background-size: cover;
	background-color: #625b47;
	width: 100%;
	height: 500px;
	position: relative;
	display: flex;
	align-items: center;
	&::after {
		content: '';
		background-color: $white;
		height: 300px;
		position: absolute;
		bottom: -237px;
		left: 0;
		right: 0;
		transform: skewY(-13deg);
	}
	h1 {
		max-width: 700px;
		font-size: rem-calc(26);
		font-weight: $heading-bold;
		color: $white;
		line-height: 1.1;
		letter-spacing: -.5px;
		text-shadow: $text-shadow;
		margin-bottom: 12px;
	}
	p {
		font-size: rem-calc(16);
		line-height: 1.3;
		color: $white;
		text-shadow: $text-shadow;
	}
	.button {
		margin-bottom: 0;
	}
	.angle {
		position: absolute;
		bottom: 0;
	}
}

.hero-boxes {
	margin-top: -50px;
	margin-bottom: 48px;
}

@include breakpoint(medium) {
	.hero {
		background-image: url('/images/hero/bg_hero_lrg.jpg');
		height: 1000px;
		&::after {
			bottom: -195px;
		}
		p {
			font-size: rem-calc(20);
		}
		h1 {
			font-size: rem-calc(42);
		}
	}
	.hero-boxes {
		margin-top: -130px;
	}
}

@include breakpoint(large) {
	.hero {
		&::after {
			bottom: -169px;
		}
		.inner {
			width: 100%;
		}
		h1 {
			font-size: rem-calc(60);
			margin-bottom: 32px;
		}
		p {
			max-width: 700px;
			font-size: rem-calc(24);
			line-height: 1.35;
			margin-bottom: 50px;
		}
	}
	.hero-boxes {
		margin-top: -180px;
	}
}


@include breakpoint(xlarge) {
	.hero {
		&::after {
			height: 600px;
			bottom: -379px;
			transform: skewY(-13deg);
		}
	}
}


//Loops over $hero-bg in _variables.scss and creates files with mixin
@each $name, $filename in $hero-bg {
  .#{$name} {
	@include hero-image($filename: $filename);
	background-color: map-get($hero-bg-color, $name);
  }
}

/* @end Hero */