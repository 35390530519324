.bg-mountain-skyline {
    background-image: url("/images/cssimg/bg_mountain_skyline.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    text-align: center;
    height: 240px;
    @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url("/images/cssimg/bg_mountain_skyline@2x.jpg");
    }
    img {
      padding-top: 30px;
    }
}