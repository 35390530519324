/*------------------------------------*\
<section class="testimonial-section content-section">
	<h2>What Our Customers Say</h2>
	<div class="testimonial-cards">
		<div class="testimonial-card">
			<div class="header">
				<img src="https://randomuser.me/api/portraits/women/95.jpg" alt="">
				<h3>Fred Rogers</h3>
			</div>
			<p>“Imagine what our real neighborhoods would be like if each of us offered, as a matter of course, just one kind word to another person.”</p>
		</div>
	</div>
</section>
\*------------------------------------*/

.testimonial-card {
	max-width: 365px;
	background-color: $white;
	padding: 24px;
	border-radius: 3px;
	margin: 0 auto 32px;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2);
	.header {
		margin-bottom: 16px;
		display: flex;
		align-items: flex-end;
		img {
			margin: 0;
		}
		h3 {
			font-size: rem-calc(22);
			font-weight: $heading-bold;
			color: $brand;
			line-height: 1.2;
			letter-spacing: .25px;
			margin-left: 16px;
			margin-bottom: 0;
		}
	}
	p {
		@include font-size(default);
		font-style: italic;
		margin-bottom: 0;
	}
}
@include breakpoint(650px) {
	.testimonial-cards {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 16px;
	}
	.testimonial-card {
		margin: 0;
	}
}

@include breakpoint(large) {
	.testimonial-cards {
		grid-template-columns: 1fr 1fr 1fr;
	}
	.testimonial-card p {
		@include font-size(default-desktop);
	}
}