/*----------------------------------------------------------*\
    Basestyles

    Base styles that are too small for individual files. The
    animate classes listed here will be the default for
    animated elements unless overriden.

\*----------------------------------------------------------*/

body {
	background: $white;
	font-family: $main-font;
	font-weight: $main-normal;
	@include font-size(default);
	color: $main-color;
	@include breakpoint(medium) {
		@include font-size(default-desktop);
	}
}
p {
	font-size: 1em;
	line-height: 1.5;
	margin: 0 0 24px;
	&.large {
		@include font-size(large);
		margin-bottom: 18px;
	}
	&.small {
		@include font-size(small);
		margin-bottom: 8px;
	}
	&.normal {
		@include font-size(default);
		margin-bottom: 24px;
	}
}
a {
	color: $link-base;
	transition: color .3s;
	text-decoration: underline;
	&:hover,
	&:focus {
		color: $link-active;
		text-decoration: none;
	}
}
hr {
	margin: 20px 0;
}
.inner {
	@include xy-grid-container;
}

/* @end */