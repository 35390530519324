/*----------------------------------------------------------*\
    Mixins

    This file contains custom mixins. All mixins should go
    here as this file is imported into both the base and
    main css files.

\*----------------------------------------------------------*/

 
/*------------------------------------*\
    @group Social Media Link Colors
\*------------------------------------*/

@mixin link-colors ($color: null, $bg: null) {
	background-color: $bg;
	color: $color;
	&:hover, &:focus {
		@if ($bg) {
			background-color: darken($bg,10%);
		}
		@if ($color and $bg == null) {
			//color: darken($color,10%);
		}
	}
}

/* @end Social Media Link Colors */

/*------------------------------------*\
    @group Hero image Sizes
\*------------------------------------*/
@mixin hero-image($filename,$extension: jpg) {
  background-image: url('/images/hero/bg_' + $filename + '.' + $extension);
  @media screen and #{breakpoint(medium)} {
    & {
      background-image: url('/images/hero/bg_' + $filename + '_lrg.' + $extension);
    }
  }
}

/* @end Hero Images */

/*------------------------------------*\
    @group Font Sizes
\*------------------------------------*/
@mixin font-size($font-size) {
  $item: map-get($font-sizes, $font-size);
  font-size: nth($item, 1);
  line-height: nth($item, 2);
}

/* @end Font Sizes */