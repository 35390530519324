.where-we-work-section {
	position: relative;
	margin-bottom: -110px;
	h2 {
		margin-bottom: 28px;
	}
	&::before {
	    content: "";
	    position: absolute;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    height: 793px;
	    background: $black;
	    transform: skewY(-11deg);
	    z-index: -1;
	}
	.map {
		overflow: hidden;
		max-width: 1400px;
		margin: 0 auto -20px;
		position: relative;
		z-index: -2;
	}
	.form {
		padding-top: 40px;
		h2 {
			font-size: rem-calc(21);
			color: $white;
		}
	}
}

@include breakpoint(410px) {
	.where-we-work-section {
		&::before {
			height: 768px;
		}
	}
}

@include breakpoint(medium) {
	.where-we-work-section {
		&::before {
			height: 785px;
		}
		.form {
			padding-top: 60px;
		}
		.form {
			h2 {
				font-size: rem-calc(30);
			}
		}
	}
}

@include breakpoint(700px) {
	.where-we-work-section {
		margin-bottom: -75px;
		&::before {
		    height: 430px;
		}
		.map {
			margin-bottom: 65px;
		}
		.form {
			padding-top: 0;
		}
	}
}

@include breakpoint(large) {
	.where-we-work-section {
		margin-bottom: -153px;
		.map {
			margin-bottom: 35px;
		}
	}
}
@include breakpoint(900px) {
	.where-we-work-section {
		margin-bottom: -75px;
	}
}

@include breakpoint(930) {
	.where-we-work-section {
		margin-bottom: -153px;
		&::before {
			height: 460px;
		}
		.map {
			margin-bottom: 2px;
		}
		.form {
			padding-top: 15px;
		}
		.form h2 {
			font-size: rem-calc(42);
		}
	}
}


//Footer form section
.angle-form-section {
	position: relative;
	margin-bottom: -110px;
	&::before {
	    content: "";
	    position: absolute;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    height: 793px;
	    background: $med-blue;
	    transform: skewY(-11deg);
	    z-index: -1;
	}
	.form {
		padding-top: 40px;
		h2 {
			font-size: rem-calc(28);
			color: $white;
		}
	}
}

@include breakpoint(410px) {
	.angle-form-section {
		&::before {
			height: 768px;
		}
	}
}

@include breakpoint(medium) {
	.angle-form-section {
		&::before {
			height: 785px;
		}
		.form {
			padding-top: 60px;
		}
	}
}

@include breakpoint(700px) {
	.angle-form-section {
		margin-bottom: -75px;
		&::before {
		    height: 430px;
		}
		.form {
			padding-top: 0;
		}
	}
}

@include breakpoint(large) {
	.angle-form-section {
		margin-bottom: -153px;
	}
}

@include breakpoint(xlarge) {
	.angle-form-section {
		&::before {
			height: 460px;
		}
		.form {
			padding-top: 15px;
		}
		.form h2 {
			font-size: rem-calc(48);
		}
	}
}