$grid-gap: 5px;

.gallery {
	margin: 0 -$grid-gap;
	display: flex;
	flex-wrap: wrap;
	&::after {
		content: '';
		flex: auto;
	}
	.gallery-item {
		flex-basis: 50%;
		padding: $grid-gap;
	}
	img {
		margin: 0;
	}
}

@include breakpoint(medium) {
	.gallery {
		.gallery-item {
			flex-basis: 33.3333%;
		}
	}
}

@include breakpoint(large) {
	.gallery {
		.gallery-item {
			flex-basis: 25%;
		}
	}
}