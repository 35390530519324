/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/

.page-footer {
    overflow: hidden;
    position: relative;
    padding-top: 400px;
    margin-top: -250px;
    overflow: hidden;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 290px;
        height: 2000px;
        background: $brand;
        transform: skewY(-11deg);
        z-index: -1;
    }
    a {
        color: $white;
        text-decoration: underline;
        line-height: 1.5;
        &:hover, &:focus {
            text-decoration: none;
            color: $brand-alt-accent;
        }
    }
    .page-footer-content {
        padding: 0 0 48px 0;
    }
    .logo {
        img {
            max-height: 68px;
        }
    }
    .nav {
        font-size: rem-calc(18);
        letter-spacing: .45px;
        margin: 60px 0;
        ul {
            padding: 0;
            margin: 0;
        }
        li {
            padding: 0 0 5px;
            border-bottom: solid 1px rgba($brand-alt, .5);
            &::before {
                display: none;
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }
    .contact-options {
        margin: 72px 0;
    }
    .contact-option {
        font-size: rem-calc(20);
        color: $white;
        letter-spacing: .5px;
        margin: 0 0 32px;
        display: flex;
        align-items: center;
        .icon {
            width: 24px;
            color: $brand-alt;
            margin-right: 24px;
            svg {
                fill: $brand-alt;
            }
        }
        .text {
            line-height: 1.2;
            font-size: rem-calc(18);
        }
        .text-label {
            font-size: rem-calc(12);
            color: $brand-alt;
            line-height: 1;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            margin-bottom: 4px;
        }
    }
    .contact-option-group:last-child .contact-option:last-child {
        margin-bottom: 0;
    }
    .logo-group {
        width: 100%;
        max-width: 315px;
        margin: 72px auto;
        display: flex;
        justify-content: space-between;
        .logo-item {
            display: flex;
            align-items: center;
            justify-content: center;
            &:first-child img {
                max-height: 65px;
            }
            &:nth-child(2) {
                margin-right: 16px;
                margin-left: 16px;
            }
        }
        img {
            margin: 0;
        }
    }
    .copyright {
        font-size: rem-calc(16);
        color: $brand-alt;
        text-align: center;
        p {
            margin-bottom: 0;
        }
    }
}

@include breakpoint(550px) {
    .page-footer {
        .contact-options {
            display: flex;
            justify-content: space-between;
        }
        .contact-option-group {
            flex-basis: 48%;
        }
    }
}


@include breakpoint(930) {
    .page-footer {
        overflow: hidden;
        position: relative;
        padding-top: 600px;
        margin-top: -300px;
        overflow: hidden;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 420px;
            height: 2000px;
            background: $brand;
            transform: skewY(-11deg);
            z-index: -1;
        }
        .inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .contact-options {
            flex-basis: 60%;
            margin: 0;
        }
        .footer-header {
            margin-bottom: 80px;
            flex-basis: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            .nav {
                font-size: rem-calc(18);
                margin: 0;
                li {
                    display: inline-block;
                    border-bottom: none;
                    padding: 0;
                    margin: 0 0 0 20px;
                }
            }
            .logo img {
                max-height: 80px;
            }
        }
        .logo-group {
            margin: 0 0 80px auto;
        }
    }
}