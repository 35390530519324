/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/


.button,
form input[type="submit"] {
	background-color: $gray;
	font-family: $alt-font;
	font-weight: $alt-normal;
	font-size: rem-calc(18);
	padding: 14px 30px;
	border-radius: $border-radius;
	border: none;
	display: inline-block;
	line-height: 1.2;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	text-decoration: none;
	&:hover,
	&:focus {
		background-color: lighten($gray, 5%);
		text-decoration: none;
	}
	svg {
		vertical-align: -2px;
		margin-left: 10px;
	}
}
.button.wide {
	min-width: 260px;
	text-align: center;
}
.button.full {
	width: 100%;
	text-align: center;
	display: block;
}
.button.small {
	font-size: rem-calc(14);
	padding: 8px 13px;
}
.button.large {
	font-size: rem-calc(32);
}
.button.short {
	padding-top: 8px;
	padding-bottom: 8px;
}
.button.alt {
	background: $brand;
	color: $white;
	&:hover,
	&:focus {
		background: lighten($brand, 10%);
	}
}