/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/

.page-header {
	height: 148px;
	background-color: $white;
	color: $white;
    padding: 15px 0;
    position: relative;
    z-index: 50;
    .inner {
    	position: relative;
    	display: flex;
        align-items: center;
    }
    .angles {
    	background-color: $brand;
    	height: 100%;
    	width: 100%;
    	transform: skewY(-10deg);
    	transform-origin: 0;
    	overflow: hidden;
    	position: absolute;
    	top: 0;
    	left: 0;
    	.angle-1 {
    		background-image: linear-gradient(-54deg, rgba(153, 0, 17, 0) 0%, rgba( 0, 0, 0, 0.5) 100%);
    		height: 66%;
    		width: 100%;
    		display: block;
    	}
    	.angle-2 {
    		background-color: $brand;
    		height: 33%;
    		width: 100%;
    		display: block;
    	}
    }
    .nav {
        margin-left: auto;
        a {
            text-decoration: none;
        }
    }
    .contact {
        > a {
            background-color: $gray;
            line-height: 1;
            border-radius: 2px;
            padding: 11px;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2);
            display: inline-block;
        }
        .icon {
            color: $white;
            line-height: 1;
            height: 18px;
        }
        .text {
            font-family: $alt-font;
            font-size: rem-calc(16);
            font-weight: $alt-normal;
            color: $white;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            margin-left: 8px;
            display: none;
        }
    }
    .logo {
        img {
            max-height: 70px;
            margin: 0;
        }
    }
    .large-nav {
        display: none;
    }
    .nav-toggle {
        margin-left: 24px;
    }
}

.hero-template .page-header{
    .contact > a {
        background-color: $brand;
    }
    .dropdown-link::after {
        @include css-triangle(5px, $brand, down);
    }
}

@include breakpoint(medium) {
	.page-header {
		.angles {
			transform: skewY(-9deg);
		}
        .contact {
            > a {
                display: flex;
                align-items: center;
            }
            .text {
                display: inline-block;
            }
        }
	}
}

@include breakpoint(700px) {
	.page-header {
		height: 464px;
        padding: 32px 0;
        .angles {
            transform: skewY(-12deg);
        }
        .logo {
            img {
                max-height: 80px;
            }
        }
        .nav {
            text-align: right;
        }
    }
}

@include breakpoint(850px) {
    .page-header {
        overflow: hidden;
        .inner {
            align-items: center;
        }
        .nav-toggle {
            display: none;
        }
        .contact {
            margin-bottom: 24px;
            display: inline-block;
        }
        .small-nav {
            display: none !important;
        }
        .large-nav {
            display: block;
        }
    }
}

@include breakpoint(1000px) {
    .page-header {
        .logo {
            img {
                max-height: 100%;
            }
        }
    }
	.page-header {
		.angles {
			transform: skewY(-9deg);
		}
	}
}


.hero-template {
	.page-header {
		width: 100%;
		background-color: transparent;
		z-index: 50;
		position: absolute;
		.angles {
			background-color: transparent;
		}
        .angle-1 {
            height: 85%;
        }
		.angle-2 {
			display: none;
		}
	}
}

@include breakpoint(700px) {
    .hero-template .page-header .angle-1 {
        height: 66%;
    }
}

/*------------------------------------*\
    @group Navigation
\*------------------------------------*/
.small-nav {
    background-color: $brand;
    width: 100%;
    min-height: 100vh;
    text-align: left;
    padding: 12px 20px;
    position: absolute;
    top: calc(100% + 16px);
    left: 0;
    display: none;
    a {
        font-family: $alt-font;
        font-weight: $alt-bold;
        font-size: rem-calc(20);
        color: $white;
    }
    li{
        margin-bottom: 12px;
    }
    .selected > a {
        color: $brand-alt-accent;
    }
    .dropdown-link {
        padding-right: 24px;
        display: inline-block;
        position: relative;
        &::after {
            @include css-triangle(5px, $brand-alt, down);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .dropdown {
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease;
        a {
            font-weight: $alt-normal;
            font-size: rem-calc(16);
        }
        li {
            margin-bottom: 4px;
        }
    }
}

.large-nav {
    font-family: $alt-font;
    font-size: rem-calc(16);
    font-weight: $alt-bold;
    color: $white;
    display: block;
    li {
        margin-left: 8px;
        display: inline-block;
        position: relative;
        &::after {
            content: '';
            background-color: $white;
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: scale(0);
            transition: transform .15s ease-in-out;
        }
        &:hover {
            cursor: pointer;
            &::after {
                transform: scale(1);
            }
        }
        &.selected {
            &::after {
                transform: scale(1);
            }
        }
    }
    a, .dropdown-link {
        color: $white;
        padding: 0 8px 12px;
    }
    .dropdown-link {
        padding-right: 24px;
        display: inline-block;
        position: relative;
        &::after {
            @include css-triangle(5px, $brand-alt, down);
            position: absolute;
            right: 7px;
            top: 12px;
        }
    }
    .dropdown-child {
        position: relative;
        &:hover, &:focus {
            &::after {
                display: none;
            }
            .dropdown {
                transform: rotateX(0);
                opacity: 1;
                visibility: visible;
            }
        }
        &.selected::after {
            bottom: 13px;
        }
    }
    .dropdown {
        background-color: $gray;
        width: 210px;
        font-weight: $alt-normal;
        line-height: 1.2;
        text-align: left;
        padding: 16px;
        border-radius: 2px;
        transform: rotateX(-40deg);
        position: absolute;
        top: 100%;
        transition: transform .2s, opacity .1s;
        visibility: hidden;
        opacity: 0;
        &::after {
            @include css-triangle(12px, $gray, up);
            position: absolute;
            left: 50%;
            top: -12px;
            transform: translateX(-50%);
        }
        li {
            font-size: rem-calc(16);
            width: 100%;
            margin-bottom: 8px;
            margin-left: 0;
            padding-left: 0;
            display: block;
            &::after {
                display: none;
            }
        }
        a:hover, a:focus, .selected a {
            color: $brand-alt;
        }
    }
}

/* @end Navigation */