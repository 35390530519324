@font-face {
    font-family: 'Neue Helvetica W01';
    src: url('/fonts/helvetica_neue_bold.woff2') format('woff2'),
        url('/fonts/helvetica_neue_bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Helvetica W01';
    src: url('/fonts/helvetica_neue_roman.woff2') format('woff2'),
        url('/fonts/helvetica_neue_roman.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira W01';
    src: url('/fonts/saira_semibold.woff2') format('woff2'),
        url('/fonts/saira_semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira W01';
    src: url('/fonts/saira_regular.woff2') format('woff2'),
        url('/fonts/saira_regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}