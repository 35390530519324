.alert-bar {
    background-color: $gray;
    padding: 20px 0 10px;
    position: relative;
    z-index: 51;
    .heading {
        font-weight: $main-bold;
        font-size: rem-calc(16);
        color: $white;
        margin-bottom: 12px;
    }
    .message {
        display: flex;
        align-items: start;
        .icon {
            min-width: 16px;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            img {
                margin-bottom: 0;
            }
            &.idaho img {
                min-width: 10px;
                min-height: 14px;
            }
            &.usa img {
                min-width: 16px;
                min-height: 10px;
            }
        }
        p {
            font-size: rem-calc(14);
            color: $white;
            margin-bottom: 10px;
        }
        &:last-child {
            p {
                margin-bottom: 0;
            }
        }
        a {
            color: $brand-alt;
            &:hover,
            &:focus {
                color: $brand-alt-accent;
            }
        }
    }
}

@include breakpoint(790) {
    .alert-bar {
        .message {
            align-items: center;
            .icon img {
                margin-bottom: 10px;
            }
            &:last-child .icon img {
                margin-bottom: 0;
            }
        }
    }
}
