/*----------------------------------------------------------*\
	Callouts

	Some of the callout styles come from the Foundation
	settings file. The styles listed here apply to the
	default callouts. Additional callout options can be
	seen on the styleguide.

\*----------------------------------------------------------*/

.callout-boxes {
	position: relative;
	.callout-box {
		max-width: 550px;
		background-color: $brand;
		padding: 16px;
		margin: 0 auto 16px;
	}
	.heading {
		display: flex;
		h2 {
			font-size: rem-calc(20);
			font-weight: $heading-normal;
			color: $white;
			line-height: 1.1;
			margin-bottom: 22px;
		}
	}
	.icon {
		color: $white;
		margin-right: 12px;
	}
	.button {
		margin-bottom: 0;
	}
	.inner.no-pad {
		padding: 0;
	}
}

@include breakpoint(medium) {
	.callout-boxes {
		.inner {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
		.callout-box {
			min-width: 400px;
			.button.wide {
				min-width: 0;
			}
		}
	}
}

@include breakpoint(large) {
	.callout-boxes {
		.inner {
			flex-wrap: nowrap;
		}
		.callout-box {
			min-height: 180px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			min-width: 0;
			flex-basis: calc(50% - 8px);
			padding: 32px;
			margin: 0;
			.heading h2 {
				font-size: rem-calc(20);
			}
		}

	}
}

//Contact callout box
.contact-callout {
	background-color: $brand;
	color: $white;
	padding: 24px 32px 32px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	h2 {
		font-size: rem-calc(21);
		line-height: 1.15;
		font-weight: $heading-bold;
		color: $white;
		margin-bottom: 12px;
	}
	p {
		@include font-size(default);
	}
	a {
		color: $white;
		font-weight: $alt-normal;
		text-decoration: none;
	}
	a:not(.button):hover {
		text-decoration: underline;
	}
	.button {
		margin: 0;
		svg {
			vertical-align: 0;
		}
	}
	.heading-icon {
		margin-left: 10px;
		svg {
			width: auto;
			height: 25px;
		}
		path {
			fill: $brand-accent;
		}
	}
	.contact-options {
		font-family: $alt-font;
		font-weight: $alt-bold;
		font-size: rem-calc(20);
		text-align: center;
		margin-top: 48px;
		.icon {
			color: $white;
			margin-right: 10px;
			height: 24px;
			svg {
				width: auto;
				height: 24px;
			}
		}
		.phone-link,
		.email-link {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.or-divider {
		height: 32px;
		width: 32px;
		font-weight: $alt-normal;
		font-size: rem-calc(14);
		text-transform: uppercase;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
		margin: 24px auto;
		border: solid 2px $white;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.callout-box.dark,
.contact-callout.dark {
	background-color: $black;
	.button {
		background-color: $brand;
		&:hover,
		&:focus {
			background-color: lighten($brand, 5%);
		}
	}
}

@include breakpoint(700) {
	.contact-callout {
		form {
			h2 {
				font-size: rem-calc(30);
			}
			.button {
				margin-bottom: 32px;
			}
		}
	}
}

@include breakpoint(large) {
	.contact-callout {
		h2 {
			font-size: rem-calc(44);
		}
		p {
			@include font-size(default-desktop);
		}
		.contact-options {
			margin-top: 32px;
			display: grid;
			grid-template-columns: 1fr 48px 1fr 48px 1fr;
			grid-column-gap: 15px;
			text-align: center;
			align-items: center;
		}
		.form-link {
			text-align: left;
		}
		.email-link {
			text-align: center;
		}
		.or-divider {
			margin: 0;
		}
	}
}

//Stacked for sidebar
.contact-callout.stacked {
	max-width: 500px;
	margin: 0 auto;
	h2 {
		font-size: rem-calc(21);
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
		letter-spacing: -.1px;
		margin-bottom: 30px;
		a {
			text-decoration: underline;
			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}
	.contact-options {
		font-size: rem-calc(22);
		font-weight: $alt-bold;
		text-align: left;
		margin-top: 0;
		.phone-link,
		.email-link {
			a:hover,
			a:focus {
				color: $brand-accent;
			}
		}
	}
	.form-link, .phone-link {
		margin-bottom: 32px;
	}
	.button {
		width: 100%;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2);
		display: block;
	}
}

@include breakpoint(large) {
	.contact-callout.stacked {
		h2 {
			font-size: rem-calc(24);
		}
		.contact-options {
			display: block;
		}
		.phone-link,
		.email-link {
			justify-content: start;
		}
	}
}