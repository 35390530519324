.text-block-grid {
	.item {
		margin: 0 0 48px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	h3 {
		font-size: rem-calc(24);
	}
	p {
		font-size: rem-calc(16);
	}
	.icon {
		svg {
			height: 74px;
		}
	}
}

@include breakpoint(700) {
	.text-block-grid {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.item {
			flex-basis: 47%;
			margin-bottom: 24px;
		}
	}
}

@include breakpoint(large) {
	.text-block-grid {
		h3 {
			font-size: rem-calc(32);
		}
		p {
			font-size: rem-calc(20);
		}
	}
}