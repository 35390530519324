/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

.page-content {
    padding: 0 0 30px;
    position: relative;
    z-index: 40;
}

//Content with sidebar
@include breakpoint(800px) {
	.content-aside {
		display: grid;
		grid-template-columns: 1fr 40%;
		grid-column-gap: 40px;
	}
}

@include breakpoint(xlarge) {
	.content-aside {
		grid-column-gap: 100px;
		grid-template-columns: 1fr 450px;
	}
}

//Contact content aside
.contact-aside {
	.icon-sidebar {
		p {
			font-family: $alt-font;
			font-size: rem-calc(22);
			color: $brand;
			line-height: 1.25;
			letter-spacing: .5px;
		}
		a {
			color: $link-base;
			text-decoration: underline;
			&:hover,
			&:focus {
				color: $link-active;
				text-decoration: none;
			}
		}

		svg {
			width: auto;
			height: 20px;
			color: $link-base;
		}
		.email svg {
			height: 18px;
		}
		.icon {
			margin-right: 10px;
		}
	}
}

.footer-overlap {
	margin-bottom: -100px;
	@include breakpoint(920) {
		margin-bottom: -200px;
	}
}