/*----------------------------------------------------------*\
    Forms

    The majority of the form styling comes from the
    Foundation settings file. Any new forms added that
    need specific styling (like search forms as seen here)
    should be added in this file.

\*----------------------------------------------------------*/

form {
	label {
		font-family: $alt-font;
		font-weight: $alt-normal;
		font-size: rem-calc(18);
		color: $brand;
		letter-spacing: .2px;
		margin-bottom: 5px;
	}
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	select,
	.button {
		margin-bottom: 32px;
	 }
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	textarea,
	select {
		background-color: $off-white;
	}
}

.contact-callout form {
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	select {
		background-color: $white;
	}
}

@include breakpoint(700) {
	.fields-3 {
		max-width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		> * {
			flex-basis: 32%;
		}
	}
	.fields-2 {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		> * {
			flex-basis: 48%;
		}
	}
	.field {
		.button {
			padding: 18px 30px;
		}
	}
}

.form.dark {
	label {
		color: $brand-alt;
	}
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	select{
		background-color: $white;
		color: $main-color;
	}
}

.form.contained {
	max-width: 740px;
}